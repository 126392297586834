<script setup></script>

<template>
	<div class="relative flex flex-shrink-0 select-none place-content-center">
		<a href="https://fezz.it" class="grid select-none place-items-center">
			<img src="assets/fezz_logo.png" class="z-50 h-16 w-16" alt="Fezz Logo" />
			<div
				class="absolute z-10 w-12 h-12 delay-500 rounded-full shadow-xl opacity-25 shadow-purple-500 bg-purple-500"
			></div>
		</a>
	</div>
</template>
