<script setup lang="ts">
	import useLanguage from "@/composables/useLanguage";
	const { languages } = useLanguage();

	const i18n = useI18n();
	const props = withDefaults(
		defineProps<{
			end: boolean;
		}>(),
		{
			end: false,
		}
	);

	const toggleLang = () => {
		if (props.end) {
			return;
		}
		const lang = i18n.locale.value === "en" ? "it" : "en";
		i18n.setLocale(lang);
	};
</script>

<template>
	<div class="dropdown" :class="{ 'dropdown-end': props.end }">
		<label
			tabindex="0"
			class="grid btn btn-ghost btn-circle place-items-center avatar ring ring-neutral ring-offset-base-100 ring-offset-2"
			@click="toggleLang"
		>
			<div class="w-12 h-12 rounded-full">
				<NuxtImg
					:src="'assets/flags/' + $i18n.locale + '.png'"
					class="carousel-slide"
					:alt="$i18n.locale"
					layout="responsive"
					quality="50"
				/>
			</div>
		</label>
		<!-- <div
			tabindex="0"
			class="mt-3 z-[10] shadow card card-compact dropdown-content w-52 bg-base-100"
		>
			<div class="card-body">
				<div class="grid grid-cols-2">
					<label
						v-for="(lang, index) in languages"
						:key="index"
						tabindex="0"
						class="grid m-4 btn btn-ghost btn-circle bg-base-300 hover:bg-base-200 ring ring-offset text-base-content avatar place-items-center"
						:class="{
							'ring-primary ring-offset-primary': $i18n.locale === lang.code,
							'ring-neutral ring-offset-neutral': $i18n.locale !== lang.code,
						}"
						@click="$i18n.setLocale(lang.code)"
					>
						<div class="w-10 h-10 rounded-full">
							<NuxtImg
								:src="'assets/flags/' + lang.code + '.png'"
								class="carousel-slide"
								:alt="lang.name"
								layout="responsive"
								quality="50"
							/>
						</div>
					</label>
				</div>
			</div>
		</div> -->
	</div>
</template>
