<script setup lang="ts">
	import { onMounted, onUnmounted, ref } from "vue";

	const isOpen = ref<boolean>(false);
	const isScrolled = ref<boolean>(false);

	const handleScroll = () => {
		isScrolled.value = window.scrollY > 100;
	};

	onMounted(() => {
		window.addEventListener("scroll", handleScroll);
	});

	onUnmounted(() => {
		window.removeEventListener("scroll", handleScroll);
	});
</script>

<template>
	<div class="fixed z-50 w-full px-4">
		<div
			class="navbar rounded-md max-w-7xl shadow-lg mx-auto mt-4 px-8 shadow-sm  border transition-all delay-400 duration-600"
			:class="{
				'bg-gray-200/5 border-white/20': !isScrolled && !isOpen,
				'bg-gray-50/95 border-purple-500 shadow-purple-500/20 scale-[1.01]': isScrolled || isOpen,
				'grid text-center': isOpen,
			}"
		>
			<div
				:class="{
					'flex-1 navbar-start': !isOpen,
					'w-full grid place-items-center': isOpen,
				}"
			>
				<NavigationNavBrand />
			</div>
			<div
				class="flex-none gap-6  navbar-center"
				:class="{ 'hidden lg:flex': !isOpen, grid: isOpen }"
			>
				<NavigationNavMenu :is-open="isOpen" />
				<div
					:class="{
						'grid grid-cols-1 gap-6': !isOpen,
						'flex justify-between m-4': isOpen,
					}"
				>
					<NavigationNavLangSwitch :end="false" />
				</div>
			</div>
			<label
				tabindex="0"
				class="btn btn-ghost text-purple-500 border-purple-500 bg-gray-100 hover:bg-gray-100 hover:border-purple-500"
				:class="{ 'lg:hidden': !isOpen }"
				@click="isOpen = !isOpen"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="w-5 h-5"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M4 6h16M4 12h8m-8 6h16"
					/>
				</svg>
			</label>
		</div>
	</div>
	<!--nav
        class="relative top-0 z-50 flex flex-wrap items-center justify-between w-full p-4 bg-gray-100 shadow shadow-lg dark:bg-gray-900"
    >
        <NavBrand/>
        <NavToggle v-model="isOpen"/>
        <NavMenu :is-open="isOpen"/>
    </!--nav-->
</template>
