import { ref } from "vue";
import type { Language } from "~/types/App";

export default function () {
	const languages = ref<Language[]>([
		{
			name: "Italiano",
			code: "it",
		},
		{
			name: "English",
			code: "en",
		},
		// {
		// 	name: "Deutsch",
		// 	code: "de",
		// },
		// {
		// 	name: "Nederlands",
		// 	code: "nl",
		// },
		// {
		// 	name: "Français",
		// 	code: "fr",
		// },
		// {
		// 	name: "Español",
		// 	code: "es",
		// },
	]);

	return {
		languages,
	};
}
