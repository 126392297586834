<script setup lang="ts">
	interface Props {
		isOpen: boolean;
	}

	interface Route {
		name: string;
		href: string;
		component: string;
	}
	const props = withDefaults(defineProps<Props>(), {
		isOpen: false,
	});

	const routes = ref<Route[]>([
		{
			name: "Home",
			href: "index",
			component: "Homepage/Homepage",
		},
		// {
		// 	name: "About me",
		// 	href: "about",
		// 	component: "AboutMe/AboutMe",
		// 	//href: route('home'),
		// 	// active: false,
		// },
		// {
		//     name: "Projects",
		//     href: "projects",
		//     component: "Project",
		// },
		// {
		// 	name: "Blog",
		// 	href: "blog",
		// 	component: "Blog",
		// 	//href: route('home'),
		// 	// active: false,
		// },
		// {
		// 	name: "Contact me",
		// 	href: "contact",
		// 	component: "ContactMe",
		// 	//href: route('home'),
		// 	// active: false,
		// },
		//'projects',
		//'about',
		//'contact',
	]);
</script>

<template>
	<ul
		class="gap-2 px-1 menu "
		:class="{
			'menu-vertical': props.isOpen,
			'menu-horizontal': !props.isOpen,
		}"
	>
		<NavigationNavLink v-for="routeItem in routes" :routeName="routeItem.href">
			{{ routeItem.name }}
		</NavigationNavLink>
	</ul>
	<!--div
        class="flex-grow w-full lg:flex lg:items-center lg:w-auto"
        :class="{ 'block shadow-3xl text-center': isOpen, 'hidden': !isOpen }"
    >
    <ul
        class="items-center justify-end flex-1 pt-6 transition duration-500 ease-in-out lg:pt-0 list-reset lg:flex"
    >
        <NavLink v-for="routeName in routes" :destination="routeName" :is-open="isOpen">
            {{ $t('nav.' + routeName) }}
        </NavLink>
        <div class="mx-4">
            <ThemeSwitch class="float-left"></ThemeSwitch>
            <LanguageSwitch class="float-right"></LanguageSwitch>
        </div>
    </ul>
</!--div-->
</template>
