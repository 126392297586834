<script setup lang="ts">
	const localePath = useLocalePath();

	const props = withDefaults(
		defineProps<{
			routeName: string;
			wrapper?: "li" | "div";
		}>(),
		{
			wrapper: "li",
		}
	);
</script>

<template>
	<component :is="props.wrapper">
		<NuxtLink
			:to="localePath({ name: props.routeName })"
			class="bg-transparent rounded-sm text-gray-950 py-3 px-4 uppercase text-md font-semibold transition-all"
			activeClass="!bg-gray-800 !text-white"
		>
			<slot />
		</NuxtLink>
	</component>
</template>
